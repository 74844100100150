<template>
  <div class="Carousel">
    <div class="Carousel_main">
      <div class="Carousel_main_list">
        <!-- 方案展示 -->
        <div
          class="Carousel_main_listItem"
          v-for="(item, index) in CarouselList"
          :key="index"
        >
          <div class="Carousel_main_listItem_img">
            <img :src="item.img_url" />
          </div>
          <div class="Carousel_main_listItem_operate">
            <div @click="openEditDialog(item)">编辑</div>
            <div @click="submitDeleteCarouselData(item.sli_id)">删除</div>
          </div>
        </div>
        <div class="Carousel_main_listAdd" @click="openAddDialog()">
          <i class="el-icon-plus"></i>
        </div>
      </div>
    </div>
    <!-- 编辑弹窗 -->
    <div>
      <el-dialog :title="titleDialog" :visible.sync="dialogVisible" width="30%">
        <div>
          <div class="editDialog_top">
            <div style="margin: 10px 0">方案：</div>
            <el-input v-model="CarouselName" placeholder="请选择方案" disabled>
              <i
                style="color: #000;"
                slot="suffix"
                class="el-input__icon el-icon-circle-plus"
                @click="openSelectPlan()"
              ></i>
            </el-input>
          </div>
          <div class="editDialog_main">
            <div>轮播图片：</div>
            <div class="editDialog_main_action">
              <div class="editDialog_upload">
                <el-upload
                  action="https://img.centurysouth.cn/group1/upload"
                  :on-success="handleSuccess"
                  :show-file-list="false"
                >
                  <el-button size="small" type="primary">上传轮播图</el-button>
                </el-upload>
              </div>
              <div class="select_img">
                <el-button size="small" type="danger" @click="openSelectImg()">
                  选择轮播图
                </el-button>
              </div>
            </div>
            <div class="editDialog_img">
              <img :src="CarouselImg" />
            </div>
          </div>
          <div slot="footer" class="editDialog_footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button
              type="primary"
              @click="
                titleDialog == '新增弹窗'
                  ? submitAddCarouselData()
                  : submitEditCarouselData()
              "
              >确认</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 展览侧边弹窗 -->
    <div>
      <el-drawer :visible.sync="drawer">
        <div v-if="view_imgs" class="view_imgs">
          <div
            v-for="(item, index) in view_imgs"
            :key="index"
            class="view_imgs_item"
          >
            <img :src="item.img_url" />
            <div class="view_imgs_select" @click="selectCarousel(item.img_url)">
              <i class="el-icon-s-promotion"></i>
            </div>
          </div>
        </div>
        <div v-else class="selectDrawer_box">
          <!-- 搜索框 -->
          <div class="selectDrawer_top">
            <el-input
              placeholder="请输入方案名称"
              v-model="selectDrawer_val"
              clearable
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
          <div class="selectDrawer_sort"></div>
          <!-- 方案列表 -->
          <div class="selectDrawer_main">
            <div v-for="(item, index) in schemeList" :key="index">
              <div>
                <div class="selectDrawer_main_img">
                  <img :src="item.cover" />
                </div>
                <div class="selectDrawer_main_title">
                  <div>{{ item.title }}</div>
                </div>
                <div
                  class="selectDrawer_main_action"
                  @click.stop="selectCurrentPlan(item.id, item.title)"
                >
                  <i
                    class="el-icon-success"
                    :style="
                      CarouselId == item.id ? 'color: #F56C6C;' : 'color: #fff;'
                    "
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  data() {
    return {
      // 轮播图数据
      CarouselList: [],
      // 方案数据
      schemeList: [],
      // 编辑弹窗状态
      dialogVisible: false,
      // 展览弹窗状态
      drawer: false,
      // 轮播图id
      CarouselId: "",
      // 轮播图名称
      CarouselName: "",
      // 轮播图
      CarouselImg: "",
      // 搜索方案值
      selectDrawer_val: "",
      // 标题
      titleDialog: "",
      // 方案详情图列表
      view_imgs: "",
    };
  },
  created() {
    this.getCarouselList();
    this.getSchemeList();
  },
  methods: {
    // 获取轮播图数据
    getCarouselList() {
      this.$axios({
        url: this.$api + "/auth/index/slideshow",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.CarouselList = res.data.data;
        }
      });
    },
    // 获取方案数据
    getSchemeList() {
      this.$axios({
        url: this.$api + "/auth/ppt/indexlist",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: 1,
          title: "",
          username: "",
          cat_id: "",
          hot: "",
          new: "",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.schemeList = res.data.data.res;
        }
      });
    },
    // 打开编辑弹窗
    openEditDialog(item) {
      this.schemeList.map((list,key) => {
        if(item.id == list.id){
          this.CarouselName = list.title;
        }
      });
      this.CarouselId = item.id;
      this.CarouselImg = item.img_url;
      this.sli_id = item.sli_id;
      this.dialogVisible = true;
      this.titleDialog = "编辑弹窗";
    },
    // 打开添加弹窗
    openAddDialog() {
      this.CarouselId = "";
      this.CarouselName = "";
      this.CarouselImg = "";
      this.dialogVisible = true;
      this.titleDialog = "新增弹窗";
    },
    // 上传成功后的钩子
    handleSuccess(response, file, fileList) {
      this.CarouselImg = response;
    },
    // 选择跳转方案
    openSelectPlan() {
      this.CarouselId == "";
      this.view_imgs = "";
      this.drawer = true;
    },
    // 选择当前方案
    selectCurrentPlan(id, title) {
      this.CarouselId = id;
      this.CarouselName = title;
    },
    // 添加轮播图
    submitAddCarouselData() {
      if (this.CarouselId == "") {
        this.$message({
          showClose: true,
          message: "方案id不能为空",
          type: "warning",
        });
        return;
      }
      if (this.CarouselImg == "") {
        this.$message({
          showClose: true,
          message: "轮播图不能为空",
          type: "warning",
        });
        return;
      }
      this.$axios({
        url: this.$api + "/admin/ppt/handleslideshow",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/x-www-form-urlencoded;",
        },
        data: {
          img_url: this.CarouselImg,
          item_id: this.CarouselId,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
          this.getCarouselList();
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 修改轮播图
    submitEditCarouselData() {
      if (this.CarouselId == "") {
        this.$message({
          showClose: true,
          message: "方案id不能为空",
          type: "warning",
        });
        return;
      }
      if (this.CarouselImg == "") {
        this.$message({
          showClose: true,
          message: "轮播图不能为空",
          type: "warning",
        });
        return;
      }
      this.$axios({
        url: this.$api + "/admin/ppt/handleslideshow",
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/x-www-form-urlencoded;",
        },
        data: {
          img_url: this.CarouselImg,
          item_id: this.CarouselId,
          sli_id: this.sli_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
          this.getCarouselList();
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 删除轮播图
    submitDeleteCarouselData(sli_id) {
      this.$confirm("此操作将永久删除该轮播图, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: this.$api + "/admin/ppt/handleslideshow",
            method: "DELETE",
            headers: {
              Authorization: localStorage.getItem("user_token"),
              "Content-Type": "application/x-www-form-urlencoded;",
            },
            data: {
              sli_id: sli_id,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "success",
              });
              this.getCarouselList();
            } else {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 打开选择轮播图
    openSelectImg() {
      if (this.CarouselId != "") {
        this.$axios({
          url: this.$api + "/auth/ppt/details",
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("user_token"),
            "Content-Type": "application/json;charset=UTF-8;",
          },
          params: {
            item_id: this.CarouselId,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            this.view_imgs = res.data.data.view_imgs;
            this.drawer = true;
          }
        });
      } else {
        if (this.CarouselId == "") {
          this.$message({
            showClose: true,
            message: "请选择ID后再选择轮播图",
            type: "warning",
          });
          return;
        }
        this.drawer = true;
      }
    },
    // 选择轮播图
    selectCarousel(url) {
      this.CarouselImg = url;
    },
  },
};
</script>

<style scoped>
.Carousel {
  padding: 20px;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
}
/* 轮播图预览 */
.Carousel_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Carousel_top_banner {
  border-radius: 15px;
  overflow: hidden;
  width: 400px;
  height: 200px;
}
.Carousel_top_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 轮播图列表 */
.Carousel_main {
  height: 100%;
  margin: 10px 0;
  background-color: #fff;
  box-shadow: 0px 0px 10px #c5c5c5;
  border-radius: 15px;
  padding: 10px;
}
.Carousel_main_list {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  box-sizing: border-box;
}
.Carousel_main_listItem {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  margin: 1%;
  width: 23%;
}
.Carousel_main_listItem_img {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 15px;
}
.Carousel_main_listItem_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Carousel_main_listItem_operate {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -100px;
  height: 50px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s;
}
.Carousel_main_listItem:hover .Carousel_main_listItem_operate {
  bottom: 0;
}
.Carousel_main_listItem_operate > div {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Carousel_main_listItem_operate > div:nth-of-type(1) {
  background-color: #40a0ffb2;
}
.Carousel_main_listItem_operate > div:nth-of-type(2) {
  background-color: #f56c6cb9;
}
/* 添加轮播图 */
.Carousel_main_listAdd {
  width: 23%;
  height: 200px;
  border-radius: 15px;
  border: 1px solid #c5c5c5;
  cursor: pointer;
  margin: 10px;
}
.Carousel_main_listAdd > i {
  font-size: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* 添加与编剧弹窗 */
.editDialog_top {
  margin: 20px 0;
}
.editDialog_top >>> .el-input__inner{
  color: #000;
}
.editDialog_main > div {
  margin: 10px 0;
}
.editDialog_main_action {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.editDialog_img {
  width: 100%;
  height: 200px;
}
.editDialog_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.editDialog_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* 侧边弹窗 */
.selectDrawer_box {
  padding: 0 20px;
  box-sizing: border-box;
}
.selectDrawer_main {
  overflow: auto;
  height: 80vh;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.selectDrawer_main > div {
  width: 45%;
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.selectDrawer_main_img {
  width: 100%;
  height: 120px;
}
.selectDrawer_main_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.selectDrawer_main_title {
  text-align: center;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #949494;
}
.selectDrawer_main_title > div {
  width: 80%;
  margin: 0 auto;
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selectDrawer_main_action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  display: flex;
  font-size: 58px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: -1;
}
.selectDrawer_main > div:hover .selectDrawer_main_action {
  z-index: 1;
}
.view_imgs {
  overflow: auto;
  height: 100vh;
}
.view_imgs_item {
  width: 100%;
  height: 300px;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #949494;
  position: relative;
}
.view_imgs_item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.view_imgs_item:hover .view_imgs_select {
  z-index: 9;
}
.view_imgs_select {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 18px;
  background-color: #f56c6cb4;
  width: 30px;
  height: 30px;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: -1;
  cursor: pointer;
}

@media screen and (min-width: 2049px) and (max-width: 4096px) {
  .Carousel_top_banner {
    width: 800px;
    height: 400px;
  }
  .Carousel_top_banner >>> .el-carousel__container {
    height: 400px !important;
  }
  .Carousel_main_listItem_img {
    height: 400px;
  }
  .Carousel_main_listAdd {
    height: 400px;
  }
  .Carousel_main_listItem_operate {
    height: 100px;
    font-size: 36px;
  }
}
</style>